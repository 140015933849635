import React from "react";
import LayoutExamples from "../../layouts/LayoutExamples";
import Seo from "../../components/seo";
import { Result } from "antd";
import { SmileOutlined } from "@ant-design/icons";

const ExampleIndexPage = () => (
  <LayoutExamples>
    <Seo
      title="Examples"
      description="inFlow helps professional organisations create interactive decision-making tools such as flow charts, decision trees or algorithms. Improve your process and increase engagement with our world-class tools."
    />
    <Result
      icon={<SmileOutlined />}
      title="Hi, select an example"
      style={{ padding: "15vh 0", margin: "0", backgroundColor: "#f1f3f5" }}
    />
  </LayoutExamples>
);

export default ExampleIndexPage;
